<template>
  <div class="church-ministry template-1">
    <header class="page-header container" v-if="this.param != 'st'">
      <h1>{{ title }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcCurrentHome" v-if="this.param != 'st'"
        :i18n="translations.components"></camp-selector>
      <state-con v-if="this.param === 'st'"></state-con>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import SecurityBanner from '@/components/SecurityBanner'
import StateCon from '@/views/calendar/events/StateConventionsEvents.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'view-events',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      titles: [],
    }
  },
  components: {
    campSelector: CampSelect,
    securityBanner: SecurityBanner,
    stateCon: StateCon
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getTranslations(),
          this.getComponentTranslations('camp-select', 'security-banner'),
        ]).then((results) => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        })
      } catch (error) {
        console.error(`There was an error retrieving translations for module ${this.$options.name}: ${error}`)
        throw error
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.titles = {
        ax: `${this.translations.tcAuxiliaryEvents}`,
        ce: `${this.translations.tcChurchEngagementEvents}`,
        cl: `${this.translations.tcCalendar}`,
        ic: `${this.translations.tcInternationalConvention}`,
        menu: `${this.translations.tcEvents}`,
        st: `${this.translations.tcStateConventionsEvents}`,
        ub: `${this.translations.tcUpcomingBlitzes}`,
        xx: `${this.translations.tcEvents}`,
      }
    },
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    title() {
      return this.titles[this.param] || this.titles['xx']
    },
    param() {
      const fourth = this.$route.path.split('/')[4]
      return fourth === 'menu' ? 'xx' : fourth
    },
  },
}
</script>

<style lang="scss">
/* eslint-disable */
@import '@/styles/settings.scss';

.church-ministry {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .section-0 {
    margin-bottom: 60px;

    .quote-verse p {
      font-family: $lora;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 1px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .number-card .content .w-subtitle p:not(.subtitle) {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }

  .section-1 {
    .d-flex.button-bar {
      align-items: center;

      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }

    .number-card .wo-subtitle p {
      font-size: 26px;
      line-height: 32px;
    }

    .sub-text {
      font-family: $DIN;
      text-transform: uppercase;
      font-size: 20px;

      @include breakpoint(sm) {
        display: block;
        margin-left: 0 !important;
        margin-top: 30px;
      }
    }
  }

  .section-2 {
    .sub-support {
      .num {
        display: flex;
        align-items: center;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    .number-card {
      align-items: center;
    }

    .row {
      justify-content: center;
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
